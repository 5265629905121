import React, { useState } from 'react'
import Layout from '../components/Layout';
import hospitality from '../images/hospitality.jpg'
import PaddedLayout from '../components/PaddedLayout';
import './news.scss'
import "../styles.scss"


// const News = ({data}) => { 
    const News = () => { 
    return(
        <Layout>
          <PaddedLayout>
            <div className="page-wrapper">
                <div className="main-article">
                    <span className="article-date">09/21/2021</span>
                    <h4>Make your cleaning more green with Tomcat</h4>
                    <img src={ hospitality } alt="" />
                    <p>Officia id eu tempor enim irure officia. Mollit non ex proident adipisicing adipisicing incididunt nulla aute anim nostrud reprehenderit est reprehenderit. Et eiusmod elit pariatur veniam minim mollit ad ipsum ipsum in ea est voluptate. Eu elit velit mollit ullamco voluptate ut proident velit dolor exercitation laboris amet est est. Officia sunt ex dolor sunt id aute aliqua officia cillum excepteur est voluptate dolor. Ea excepteur pariatur ullamco voluptate laboris laboris sit pariatur nostrud ullamco occaecat culpa dolore proident. Mollit do ullamco dolor proident ut veniam non cupidatat quis aliqua deserunt labore ea Lorem. <a href="">Read On</a></p>
                </div>          
            </div>
            </PaddedLayout>
        </Layout>
  
    )
}

export default News
